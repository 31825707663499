export function validateCPF(cpf) {
    cpf = cpf.replace(/[^\d]/g, '');
  
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
      return false; // CPF com todos os dígitos iguais é inválido
    }
  
    let sum = 0;
    let weight = 10;
  
    for (let i = 0; i < 9; i++) {
      sum += cpf.charAt(i) * weight--;
    }
  
    let remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
  
    if (remainder !== parseInt(cpf.charAt(9))) {
      return false;
    }
  
    sum = 0;
    weight = 11;
  
    for (let i = 0; i < 10; i++) {
      sum += cpf.charAt(i) * weight--;
    }
  
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
  
    return remainder === parseInt(cpf.charAt(10));
  }
  