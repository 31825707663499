import React, { useState } from "react";
import {
  AppBar,
  Box,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  CssBaseline,
  Tooltip,
  Badge,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import AppsIcon from "@mui/icons-material/Apps";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import MoreIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/material/styles";
import "./Home.scss";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Função para abrir e fechar a sidebar
  const toggleside = () => {
    setIsOpen(!isOpen);
  };

  const currentHour = () => {
    const hours = new Date().getHours();
    if (hours >= 6 && hours < 12) return "Bom dia";
    if (hours >= 12 && hours < 18) return "Boa tarde";
    return "Boa noite";
  };

  const createMenuItems = () =>
    [
      { icon: <HomeIcon />, text: "Início" },
      { icon: <AppsIcon />, text: "Aplicativos" },
      { icon: <NotificationsIcon />, text: "Notificações" },
      { icon: <SettingsIcon />, text: "Administração" },
    ].map((item) => (
      <Tooltip title={item.text} placement="right" key={item.text}>
        <ListItem button sx={{ display: 'flex', alignItems: 'center' }}>
          <ListItemIcon sx={{ minWidth: 40 }}>
            {item.icon}
          </ListItemIcon>
          <ListItemText
            primary={item.text}
            sx={{ display: isOpen ? "block" : "none", ml: 2 }}
          />
        </ListItem>
      </Tooltip>
    ));

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"  // Garante que o AppBar fique fixo no topo
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, width: '100%' }}  // Garante que o AppBar ocupe toda a largura
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleside}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <h1>Tanis Hub</h1>

          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <h1>T</h1>
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
            >
              <Badge badgeContent={4} color="error">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton size="large" aria-label="show more" color="inherit">
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Sidebar
        collapsed={!isOpen}
        style={{
          height: "calc(100vh - 64px)",  //  altura da Sidebar para ficar abaixo do AppBar
          position: "fixed",
          left: 0,
          top: 64,  
          overflow: 'auto',
          zIndex: (theme) => theme.zIndex.drawer,
        }}
      >
        <Menu iconShape="square" sx={{ width: 250 }}>
          <MenuItem sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ListItemIcon sx={{ minWidth: 40, mr: 2 }}>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Início" />
            </Box>
          </MenuItem>
          <MenuItem sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ListItemIcon sx={{ minWidth: 40, mr: 2 }}>
                <AppsIcon />
              </ListItemIcon>
              <ListItemText primary="Aplicativos" />
            </Box>
          </MenuItem>
          <MenuItem sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ListItemIcon sx={{ minWidth: 40, mr: 2 }}>
                <NotificationsIcon />
              </ListItemIcon>
              <ListItemText primary="Notificações" />
            </Box>
          </MenuItem>
          <MenuItem sx={{ mt: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ListItemIcon sx={{ minWidth: 40, mr: 2 }}>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Administração" />
            </Box>
          </MenuItem>

          <SubMenu title="Components">
          <MenuItem>Component 1</MenuItem>
          <MenuItem>Component 2</MenuItem>
          </SubMenu>
        </Menu>
      </Sidebar>

      <main style={{ padding: "20px", marginLeft: isOpen ? 250 : 80, marginTop: 64 }}>
          <div>{currentHour()} Gilbert, tenha um bom serviço hoje ❤️</div>

          <div>Outro Título</div>
          <div>Outro Título 2</div>
      </main>
    </>
  );
};

export default Home;
