import React, { useState } from "react";
import {
  FormControl,
  TextField,
  Button,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";
import FormHelperText from "@mui/material/FormHelperText";
import "./Signup.scss";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import ArticleIcon from "@mui/icons-material/Article";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { validateCPF } from "../../components/validaCPF";
import { Adulto } from "../../components/verificaIdade";
import { useNavigate } from "react-router-dom";

const steps = [
  "Criação de Conta",
  "Contrato e Aceitação",
  "Validação por E-mail",
];

const Signup = () => {
  const navigate = useNavigate();

  const Voltar = () => {
    navigate("/Login");
  };

  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    birthdate: null,
    cpf: "",
    email: "",
    password: "",
    confirmPassword: "",
    termsAccepted: false,
    contractFile: null,
  });
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");

  const [birthDate, setBirthDate] = useState("");
  const [isOfAge, setIsOfAge] = useState(null);

  const [cpf, setCpf] = useState("");

  const [isValid, setIsValid] = useState(null);

  const [errors, setErrors] = useState({
    terms: false,
  });

  const handleChangepassword = (e) => {
    const value = e.target.value;
    setPassword(value);

    // Validação da senha
    const hasLetter = /[a-zA-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    const isValidLength = value.length >= 8;

    if (hasLetter && hasNumber && isValidLength) {
      setError("");
    } else {
      setError(
        "A senha deve ter pelo menos 8 caracteres, contendo letras e números."
      );
    }
    formData.password = value;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    setBirthDate(date);

    const adult = Adulto(date);
    setIsOfAge(adult);

    formData.birthdate = date;
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, termsAccepted: e.target.checked });
  };

  const handleChangeCPF = (event) => {
    const value = event.target.value;
    setCpf(value);

    const valid = validateCPF(value);
    setIsValid(valid);

    formData.cpf = value;
  };

  const handleNext = () => {
    if (activeStep === 0) {
      console.log(formData);
      if (
        !formData.name ||
        !formData.cpf ||
        !formData.birthdate ||
        !formData.email ||
        !formData.password ||
        !formData.confirmPassword
      ) {
        alert("Preencha todos os campos obrigatórios.");
        return;
      }

      if (formData.password !== formData.confirmPassword) {
        alert("As senhas não coincidem.");
        return;
      }
    }

    if (activeStep === 1) {
      if (!formData.termsAccepted) {
        setErrors({ terms: true });
        return;
      }

      setErrors({ terms: false });
    }

    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.termsAccepted) {
      setErrors({ terms: true });
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      alert("As senhas não coincidem.");
      return;
    }

    console.log("Formulário enviado com sucesso:", formData);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const QontoStepIconRoot = styled("div")(({ theme }) => ({
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    "& .QontoStepIcon-completedIcon": {
      color: "#784af4",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[700],
    }),
    variants: [
      {
        props: ({ ownerState }) => ownerState.active,
        style: {
          color: "#784af4",
        },
      },
    ],
  }));

  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
  };

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(72,187,248) 0%,rgb(29,90,132) 50%,rgb(76,28,132) 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(72,187,248) 0%,rgb(29,90,132) 50%,rgb(76,28,132) 100%)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: "#eaeaf0",
      borderRadius: 1,
      ...theme.applyStyles("dark", {
        backgroundColor: theme.palette.grey[800],
      }),
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme }) => ({
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[700],
    }),
    variants: [
      {
        props: ({ ownerState }) => ownerState.active,
        style: {
          backgroundImage:
            "linear-gradient( 136deg, rgb(72,187,248) 0%, rgb(29,90,132) 50%, rgb(76,28,132) 100%)",
          boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
        },
      },
      {
        props: ({ ownerState }) => ownerState.completed,
        style: {
          backgroundImage:
            "linear-gradient( 136deg, rgb(72,187,248) 0%, rgb(29,90,132) 50%, rgb(76,28,132) 100%)",
        },
      },
    ],
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <SettingsIcon />,
      2: <ArticleIcon />,
      3: <MarkEmailReadIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
    icon: PropTypes.node,
  };

  return (
    <>
      <div className="conteiner">
        <Box className="signup-container">
          <Box className="card">
            <h2 className="title">Criação de Conta</h2>

            <Stepper
              activeStep={activeStep}
              alternativeLabel
              connector={<ColorlibConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>

            <form onSubmit={handleSubmit}>
              {activeStep === 0 && (
                <Box>
                  <FormControl
                    fullWidth
                    margin="normal"
                    className="form-control"
                  >
                    <TextField
                      label="Nome"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className="input-field name-input"
                    />
                  </FormControl>

                  <FormControl
                    fullWidth
                    margin="normal"
                    className="form-control"
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="pt-br"
                    >
                      <DatePicker
                        label="Data de Nascimento"
                        value={formData.birthdate}
                        onChange={handleDateChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="date-picker-input"
                          />
                        )}
                      />
                      {isOfAge === false && (
                        <p style={{ color: "red" }}>
                          Cadastro não autorizado por ser menor de idade.
                        </p>
                      )}
                    </LocalizationProvider>
                  </FormControl>

                  <FormControl
                    fullWidth
                    margin="normal"
                    className="form-control"
                  >
                    <TextField
                      label="CPF"
                      name="cpf"
                      value={formData.cpf}
                      onChange={handleChangeCPF}
                      pattern="\d{3}\.\d{3}\.\d{3}-\d{2}"
                      placeholder="123.456.789-00"
                      required
                      className="input-field cpf-input"
                    />
                    {isValid === false && (
                      <p style={{ color: "red" }}>CPF inválido</p>
                    )}
                  </FormControl>

                  <FormControl
                    fullWidth
                    margin="normal"
                    className="form-control"
                  >
                    <TextField
                      label="E-mail"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      type="email"
                      required
                      className="input-field email-input"
                    />
                  </FormControl>

                  <FormControl
                    fullWidth
                    margin="normal"
                    className="form-control"
                  >
                    <TextField
                      label="Senha"
                      name="password"
                      value={formData.password}
                      onChange={handleChangepassword}
                      type={showPassword ? "text" : "password"}
                      required
                      className="input-field password-input"
                      error={!!error}
                    />
                    {error && <FormHelperText>{error}</FormHelperText>}
                  </FormControl>

                  <FormControl
                    fullWidth
                    margin="normal"
                    className="form-control"
                  >
                    <TextField
                      label="Confirmar Senha"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      type={showConfirmPassword ? "text" : "password"}
                      required
                      className="input-field confirm-password-input"
                    />
                  </FormControl>

                  <Button
                    disabled={error || isValid === false || isOfAge === false}
                    onClick={handleNext}
                    variant="contained"
                    color="primary"
                  >
                    Próximo
                  </Button>
                </Box>
              )}

              {activeStep === 1 && (
                <Box>
                  <h3 className="subtitle">
                    Leia o contrato e aceite para continuar
                  </h3>
                  <Box className="contract-container">
                    <div>
                      <TextField
                        multiline
                        rows={10}
                        value=""
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    </div>
                  </Box>

                  <FormControl
                    fullWidth
                    margin="normal"
                    className="form-control"
                  >
                    <div className="terms-container">
                      <input
                        type="checkbox"
                        id="terms"
                        name="termsAccepted"
                        checked={formData.termsAccepted}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor="terms" className="terms-label">
                        Li e aceito os termos de uso e o contrato.
                      </label>
                    </div>
                    {errors.terms && (
                      <Typography variant="caption" className="error-text">
                        Você deve aceitar os termos para continuar.
                      </Typography>
                    )}
                  </FormControl>

                  <Button
                    onClick={handleBack}
                    variant="outlined"
                    color="secondary"
                    sx={{ mr: 1 }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={!formData.termsAccepted}
                    onClick={handleNext}
                    variant="contained"
                    color="primary"
                  >
                    Próximo
                  </Button>
                </Box>
              )}

              {activeStep === 2 && (
                <Box>
                  <h3 className="subtitle">
                    Por favor, verifique seu e-mail para completar o seu
                    registro.
                  </h3>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={Voltar}
                  >
                    ok
                  </Button>
                </Box>
              )}
            </form>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Signup;
