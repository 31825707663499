import React, { useState } from "react";
import jsSHA from 'jssha';
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import {
  TextField,
  IconButton,
  InputAdornment,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import "./Login.scss";

const Login = () => {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    setIsEmailValid(validateEmail(emailValue));
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const shaObj = new jsSHA("SHA-512", 'TEXT');
    shaObj.update(password);
    const hashpassword = shaObj.getHash("HEX");
    
    console.log("Email:", email);
    console.log("Senha:", hashpassword);

  };

  return (
    <>
    <div className="contenier">
      <div className="card2">
        <div>
          <div>
            <img src={logo} alt="Logo da Empresa" className="logo" />
          </div>
          <h2>Iniciar Sessão</h2>
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <TextField
                id="email"
                label="Digite seu E-mail"
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                className="input-field"
              />
            </div>

            {isEmailValid && (
              <div className="input-container">
                <TextField
                  id="password"
                  label="Digite sua Senha"
                  value={password}
                  type="password"
                  onChange={handlePasswordChange}
                  className="input-field"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleSubmit}
                          onMouseDown={(event) => event.preventDefault()}
                        >
                          <ArrowCircleRightOutlinedIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}

            <div>
              <FormControlLabel
                control={<Checkbox  />}
                label="Lembrar-me"
              />
            </div>

            <div className="links">
              <span className="link" onClick={() => navigate("/signup")}>
                Criar conta
              </span>
              <span
                className="link"
                onClick={() => navigate("/forgot-password")}
              >
                Esqueci minha senha
              </span>
            </div>
          </form>
        </div>
      </div>
      </div>
    </>
  );
};

export default Login;
