import React from "react";
import {Button} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Reset.scss";

const ConfirmedPassword = () => {
    const navigate = useNavigate();

  return (
    <div className="card">
      <h2>Senha Alterada</h2>
      <Button variant="contained" color="primary" onClick={() => navigate("/login")}> Login </Button>
    </div>
  );
};

export default ConfirmedPassword;
