import React, { useState } from "react";
import {
  Container,
  TextField,
  MenuItem,
  Button,
  Grid,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import "./TipodeAcesso.scss";

const TipodeAcesso = () => {
  const [formData, setFormData] = useState({
    tipodeAcesso: "",
    cnpj: "",
    razaoSocial: "",
    nomeFantasia: "",
    inscricaoMunicipal: "",
    inscricaoEstadual: "",
    cnae: "",
    telefone: "",
    celular: "",
    nomeCompleto: "",
    cargo: "",
    departamento: "",
    gestorTelefone: "",
    gestorCelular: "",
    autorizadoraCNPJ: "",
    errors: {
      cnpj: null,
      razaoSocial: false,
      nomeFantasia: false,
      inscricaoMunicipal: false,
      inscricaoEstadual: false,
      cnae: false,
      telefone: false,
      celular: false,
      nomeCompleto: false,
      cargo: false,
      departamento: false,
      gestor: false,
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleTipodeAcessoChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      tipodeAcesso: e.target.value,
    }));
  };

  const handleCancel = () => {
    setFormData({
      tipodeAcesso: "",
      cnpj: "",
      razaoSocial: "",
      nomeFantasia: "",
      inscricaoEstadual: "",
      inscricaoMunicipal: "",
      cnae: "",
      telefone: "",
      celular: "",
      nomeCompleto: "",
      cargo: "",
      departamento: "",
      gestor: "",
      autorizadoraCNPJ: "",
      errors: {
        cnpj: null,
        razaoSocial: false,
        nomeFantasia: false,
        inscricaoEstadual: false,
        inscricaoMunicipal: false,
        cnae: false,
        telefone: false,
        celular: false,
        nomeCompleto: false,
        cargo: false,
        departamento: false,
        gestor: false,
      },
    });
  };

  const handleSubmit = () => {
    console.log("Dados", formData);
    alert("Cadastro concluído com sucesso!");
    handleCancel();
  };

  const handleTipoPessoaChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      tipoPessoa: e.target.value,
    }));
  };

  return (
    <Container>
      <div className="acesso-container">
        <div className="card">
          <h2 className="title">Tipo de Acesso</h2>
          <FormControl fullWidth className="form-control" sx={{ mb: 2 }}>
            <InputLabel id="simple-select-label">Tipo de Acesso</InputLabel>
            <Select
              labelId="simple-select-label"
              id="simple-select"
              value={formData.tipodeAcesso}
              label="Tipo de Acesso"
              onChange={handleTipodeAcessoChange}
              className="MuiSelect-root"
            >
              <MenuItem value={5}>Cliente Tanis</MenuItem>
              <MenuItem value={20}>Operador</MenuItem>
            </Select>
          </FormControl>

          {/* Dados para o Cliente Tanis */}
          {formData.tipodeAcesso === 5 && (
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="cnpj"
                    label="CNPJ"
                    value={formData.cnpj}
                    onChange={handleChange}
                    fullWidth
                    className="input-field"
                    placeholder="12.345.678/0000-00"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="cnae"
                    label="CNAE"
                    value={formData.cnae}
                    InputProps={{ readOnly: true }}
                    fullWidth
                    className="input-field"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="razaoSocial"
                    label="Razão Social"
                    value={formData.razaoSocial}
                    InputProps={{ readOnly: true }}
                    fullWidth
                    className="input-field"
                    required
                    error={formData.errors.razaoSocial}
                    helperText={
                      formData.errors.razaoSocial
                        ? "Razão Social é obrigatória"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="nomeFantasia"
                    label="Nome Fantasia"
                    value={formData.nomeFantasia}
                    InputProps={{ readOnly: true }}
                    fullWidth
                    className="input-field"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="inscricaoMunicipal"
                    label="Inscrição Municipal"
                    value={formData.inscricaoMunicipal}
                    InputProps={{ readOnly: true }}
                    fullWidth
                    className="input-field"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="inscricaoEstadual"
                    label="Inscrição Estadual"
                    value={formData.inscricaoEstadual}
                    InputProps={{ readOnly: true }}
                    fullWidth
                    className="input-field"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    name="telefone"
                    label="Telefone"
                    value={formData.telefone}
                    fullWidth
                    className="input-field"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    name="celular"
                    label="Celular"
                    value={formData.celular}
                    InputProps={{ readOnly: true }}
                    fullWidth
                    className="input-field"
                  />
                </Grid>
              </Grid>
            </div>
          )}
          {/* Dados para o Operador */}
          {formData.tipodeAcesso === 20 && (
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="nomeCompleto"
                    label="Nome Completo"
                    value={formData.nomeCompleto}
                    onChange={handleChange}
                    fullWidth
                    className="input-field"
                    required
                    error={formData.errors.nomeCompleto}
                    helperText={
                      formData.errors.nomeCompleto
                        ? "Nome Completo é obrigatório"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="cargo"
                    label="Cargo"
                    value={formData.cargo}
                    onChange={handleChange}
                    fullWidth
                    className="input-field"
                    required
                    error={formData.errors.cargo}
                    helperText={
                      formData.errors.cargo ? "Cargo é obrigatório" : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="departamento"
                    label="Departamento"
                    value={formData.departamento}
                    onChange={handleChange}
                    fullWidth
                    className="input-field"
                    required
                    error={formData.errors.departamento}
                    helperText={
                      formData.errors.departamento
                        ? "Departamento é obrigatório"
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="gestor"
                    label=" Gestor"
                    value={formData.gestor}
                    onChange={handleChange}
                    fullWidth
                    className="input-field"
                    required
                    error={formData.errors.gestor}
                    helperText={
                      formData.errors.gestor ? " Gestor é obrigatório" : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="telefone"
                    label="Telefone"
                    value={formData.telefone}
                    onChange={handleChange}
                    fullWidth
                    className="input-field"
                    required
                    error={formData.errors.telefone}
                    helperText={
                      formData.errors.telefone ? "Telefone  é obrigatório" : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="celular"
                    label="Celular"
                    value={formData.celular}
                    onChange={handleChange}
                    fullWidth
                    className="input-field"
                    required
                    error={formData.errors.celular}
                    helperText={
                      formData.errors.celular ? "Celular  é obrigatório" : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="autorizadoraCNPJ"
                    label="CNPJ da Organização"
                    value={formData.autorizadoraCNPJ}
                    onChange={handleChange}
                    fullWidth
                    className="input-field"
                    placeholder="12.345.678/0000-00"
                    inputProps={{
                      maxLength: 18,
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          )}

          {(formData.tipodeAcesso === 5 || formData.tipodeAcesso === 20) && (
            <div className="actions">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{ mt: 2, mr: 1 }}
              >
                Confirmar
              </Button>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default TipodeAcesso;
