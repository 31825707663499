import React, { useState } from "react";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { useNavigate } from 'react-router-dom';

import "./recuperarsenha.scss";

const RecuperarSenha = () => {
  
  const navigate = useNavigate();

  const ConfirmaPage = () => {
      navigate('/confirmacaoenvio');
    };
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    setIsEmailValid(validateEmail(emailValue));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Email:", email);
  };

  return (
    <>
      <div className="card">
        <h2>Redefinir sua senha</h2>
        <label> Para continuar, insira o endereço de e-mail que você usa com sua conta.</label>
          <div className="form">
            <form onSubmit={handleSubmit}>
              <TextField
                id="email"
                label="Digite seu E-mail"
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                className="input-field"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={ConfirmaPage}
                        onMouseDown={(event) => event.preventDefault()} // Impede o foco indesejado
                      >
                        <ArrowCircleRightOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </div>
      </div>
    </>
  );
};

export default RecuperarSenha;
