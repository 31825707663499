// src/App.jsx
import React, { Fragment } from "react";
import RoutesApp from "./routes"; // Rotas da aplicação
import GlobalStyle from "./styles/GlobalStyle"; // Estilos globais

function App() {
  return (
    <>
      <Fragment>
        <GlobalStyle /> {/* Aplica os estilos globais */}
          <RoutesApp /> {/* Renderiza as rotas */}
      </Fragment>
    </>
  );
}

export default App;
