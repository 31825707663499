import React, { useState } from "react";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Reset.scss";

const Reset = () => {
  const navigate = useNavigate();
  const [novaSenha, setNovaSenha] = useState("");
  const [confirmaSenha, setConfirmaSenha] = useState("");
  const [senhaValida, setSenhaValida] = useState(true);
  const [senhaConfirmaValida, setSenhaConfirmaValida] = useState(true);
  const [showConfirmaSenha, setShowConfirmaSenha] = useState(false);

  const handleNovaSenhaChange = (event) => {
    setNovaSenha(event.target.value);
    const isSenhaValida = event.target.value.length >= 8 && /[a-zA-Z]/.test(event.target.value) && /[0-9]/.test(event.target.value);
    setSenhaValida(isSenhaValida);
  };

  const handleConfirmaSenhaChange = (event) => {
    setConfirmaSenha(event.target.value);
    setSenhaConfirmaValida(novaSenha === event.target.value);
  };  

  return (
    <div className="card">
      <h2>Redefinição de Senha</h2>
      <form className="form">
        <div className="input-group">
          <TextField
            id="novaSenha"
            type="password"
            label="Digite sua nova Senha"
            value={novaSenha}
            onChange={handleNovaSenhaChange}
            variant="outlined"
            fullWidth           
            error={!senhaValida}
            helperText={!senhaValida ? "A senha deve conter pelo menos 8 dígitos, letras e números." : ""}
          />
        </div>

        <div className="input-group">
          <TextField
            id="confirmaSenha"
            type="password"
            label="Confirme sua senha"
            value={confirmaSenha}
            onChange={handleConfirmaSenhaChange}
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: (
                <>                 
                  <InputAdornment position="end">
                    <IconButton
                      type="submit"
                      className="submit-icon"
                      onMouseDown={(event) => event.preventDefault()}
                      onClick={() => navigate("/New-Password")}
                    >
                      <ArrowCircleRightOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                </>
              ),
            }}
            error={!senhaConfirmaValida}
            helperText={!senhaConfirmaValida ? "As senhas não coincidem." : ""}
          />
        </div>
      </form>
    </div>
  );
};

export default Reset;
