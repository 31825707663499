// src/routes/index.js
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from '../page/Login/Login';
import Home from '../page/Home/Home';
import Signup from '../page/Signup/Signup';
import TipodeAcesso from '../page/TipodeAcesso/TipodeAcesso';
import RecuperarSenha from '../page/RecuperarSenha/recuperarsenha';
import ConfirmaPageRec from '../page/RecuperarSenha/confirmacaoenvio';
import Reset from '../page/Reset/Reset';
import ConfirmedPassword from '../page/Reset/ConfirmedPassword';
import ConfirmEmail from '../page/ConfirmEmail/ConfirmEmail';


const RoutesApp = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/tipodeacesso" element={<TipodeAcesso />} />
        <Route path="/forgot-password" element={<RecuperarSenha />} />
        <Route path="/confirmacaoenvio" element={<ConfirmaPageRec />} />
        <Route path="/reset-password/:token" element={<Reset />} />
        <Route path="/Confirm-EMail/:token" element={<ConfirmEmail />} />
        <Route path="/New-Password" element={<ConfirmedPassword />} />
        <Route path="/Home" element={<Home />} />
        <Route path="*" element={<Login />} /> {/* Página padrão */}
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesApp;
