import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';

import "./recuperarsenha.scss";

const ConfirmacaoEnvio = () => { 

  const navigate = useNavigate();

  const Voltar = () => {
      navigate('/Login');
    }; 

  return (
    <>
      <div className="card">
        <h2>Redefinir sua senha</h2>        
        <label> Verifique seu e-mail para redefinir sua senha</label>
        <div>
        <Button variant="contained" color="primary" onClick={Voltar} sx={{ mt: 2 }}>Ok</Button>
        </div>        
      </div>
    </>
  );
};

export default ConfirmacaoEnvio;
